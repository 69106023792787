import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faCalendarAlt, faTimes, faTrashAlt, faPen } from '@fortawesome/free-solid-svg-icons';
import { trackLink } from '../GlobalFunctions';

class AccountPage extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            jsxList: null,
            cmpHasUser: false,
            sortBy: "none",
            isDesc: false,
            item: null,
            showDeleteConf: false,
            deleted: false
        }
        this.confirmDeleteMeal = this.confirmDeleteMeal.bind(this);
        this.deleteMeal = this.deleteMeal.bind(this);
        this.hideDeleteConf = this.hideDeleteConf.bind(this);
        this.getData = this.getData.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.user !== null && !this.state.cmpHasUser)) {
            this.getData();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return !this.state.cmpHasUser || (nextState !== this.state)
    }
    confirmDeleteMeal(item) {
        this.setState({
            itemDelete: item,
            showDeleteConf: true
        })
    }
    deleteMeal() {
        let db = firebase.firestore(this.props.firebase);
        let cmp = this;
        db.collection("meals").doc(this.state.itemDelete.id).delete().then(function() {
            cmp.getData();
            cmp.setState({
                deleted: true
            });
            trackLink("Delete Meal", "Success");
        }).catch(function(error) {
            console.error("Error removing document: ", error);
            trackLink("Delete Meal", "Failure");
        });
    }
    hideDeleteConf(item) {
        this.setState({
            itemDelete: null,
            showDeleteConf: false,
            deleted: false
        })
    }
    getData() {
        const cmp = this;
        const userId = this.props.user.uid;
        let db = firebase.firestore(this.props.firebase);
        db.collection("meals").where("user", "==", userId)
        .get()
        .then(function(querySnapshot) {
            let list = [];
            querySnapshot.forEach(function(doc) {
                let json = doc.data();
                json.id = doc.id;
                list.push(json);
            });
            cmp.setState(() => ({
                cmpHasUser: true,
                jsxList: list
            }));
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    }
    sortData(category) {
        let compare = this.getCompareFunction(category);
        let list = this.state.jsxList.sort(compare);
        this.setState(() => ({
            jsxList: list,
            sortBy: category,
            isDesc: !this.state.isDesc
        }));
    }
    getCompareFunction(category) {
        if (this.state.isDesc) {
            return (a, b) => {
                if (category === "created") {
                    a[category] = new Date(a[category]);
                    b[category] = new Date(b[category]);
                }
                if (a[category] < b[category]) {
                    return 1;
                }
                else if (a[category] > b[category]) {
                    return -1;
                }
                return 0;
            }
        }
        return (a, b) => {
            if (category === "created") {
                a[category] = new Date(a[category]);
                b[category] = new Date(b[category]);
            }
            if (a[category] > b[category]) {
                return 1;
            }
            else if (a[category] < b[category]) {
                return -1;
            }
            return 0;
        }
    }
	render() {
        let list;
        if (this.state.jsxList !== null) {
            list = this.state.jsxList.map((item, i) => {
                let date = new Date(item.created);
                let minutes = date.getMinutes();
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }
                let hours = date.getHours();
                let suffix = hours < 12 ? "am" : "pm";
                if (hours > 12) {
                    hours -= 12;
                }
                else if (hours === 0) {
                    hours = 12;
                }
                return <tr key={"meal"+i}><td>{item.title}</td><td>{(date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + hours + ":" + minutes + suffix}</td><td className="meal-options right"><a
                onClick={() => trackLink("Account Page|View Meal", item.id)} className="btn-small btn-primary" href={"/meal/" + item.id}><FontAwesomeIcon icon={faPen}/><span className="hide-on-mobile ml-5">Edit</span></a><button onClick={() => this.confirmDeleteMeal(item)} className="btn-small btn-red ml-5" type="button"><FontAwesomeIcon icon={faTrashAlt} /><span className="off-screen">Delete</span></button></td></tr>
            })
        }
		return (
			<div>
                {!this.props.user && <div id="spinner"><div className="loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
                {this.props.user !== null && <div>
                    <p>Name: {this.props.user.displayName}</p>
                    <p>Email: {this.props.user.email}</p>
                    {this.state.jsxList !== null && <div className="remove-margin">
                        <table className="table table-striped saved-meals-table" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr className="menu-break">
                                    <th className="saved-meals-col"><button className={(this.state.sortBy === "title" && this.state.isDesc) ? "up-arrow" : "down-arrow"} onClick={() => { this.sortData("title")}}><FontAwesomeIcon icon={faUtensils} className="mr-10" />Meal Name </button></th>
                                    <th className="saved-meals-col"><button className={(this.state.sortBy === "created" && this.state.isDesc) ? "up-arrow" : "down-arrow"} onClick={() => { this.sortData("created")}}><FontAwesomeIcon icon={faCalendarAlt} className="mr-10" />Date Created</button></th>
                                    <th className="saved-meals-col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length > 0 ? list : <tr><td colSpan="2" >No saved meals.</td></tr>}
                            </tbody>
                        </table>
                    </div>}
                    {(this.state.showDeleteConf && this.state.itemDelete != null) && <div className="modal-bg">
                        <div className="modal-container">
                            <button className="btn-close" onClick={this.hideDeleteConf}>
                                <FontAwesomeIcon icon={faTimes} className="close-icon" />
                                <span className="off-screen">Close modal</span>
                            </button>
                            {!this.state.deleted && <div id="delete-conf-content">
                                <h3>Are you sure you want to delete "{this.state.itemDelete.title}"?</h3>
                                <p>This action is permanent can't be undone.</p>
                                <button type="button" className="btn btn-primary width-25" onClick={this.deleteMeal}>Yes, delete this meal</button>
                                <button type="button" className="btn btn-alt width-25 ml-10" onClick={this.hideDeleteConf}>No</button>
                            </div>}
                            {this.state.deleted && <div id="delete-conf-content">
                                <h3>Your meal was deleted.</h3>
                                <button onClick={this.hideDeleteConf} className="btn btn-alt">Back</button>
                            </div>}
                        </div>
                    </div>}
                </div>} 
            </div>
		);
	}
};

export default AccountPage;