import React from 'react';
import { trackLink } from '../GlobalFunctions';

class Unauthorized extends React.Component {
    render() {
        return (
            <div>
                <h1 id="heading">Unauthorized</h1>
                <p>Please sign in to view this page.</p>
                <a onClick={() => trackLink("Unauthorized", "Log In Button")} className="btn btn-alt" href="/login">Log In</a>
                <a onClick={() => trackLink("Unauthorized", "Home Button")} className="btn btn-alt ml-10" href="/">Back to Home</a>
            </div>
        );
    }
}

export default Unauthorized;