import React from 'react';
import MenuBreakItem from './MenuBreakItem';
import MenuItem from './MenuItem';
import MobileTotals from './MobileTotals';
import SaveMeal from './SaveMeal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { messageBanner, errorMessageBanner, trackLink } from '../GlobalFunctions';

class MenuTable extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            totals: {
                totalCal: 0,
                totalFatCal: 0,
                totalFat: 0,
                totalSatFat: 0,
                totalTransFat: 0,
                totalChol: 0,
                totalSodium: 0,
                totalCarbs: 0,
                totalFiber: 0,
                totalSugar: 0,
                totalProtein: 0
            },
            isMobile: window.innerWidth < 992,
            resetState: false,
            showCalculator: false,
			hasJuice: false,
			hasAllergies: false,
			markup: null,
            preselectIsDone: false,
            showSaveMeal: false
        }
        this.calculateItem = this.calculateItem.bind(this);
        this.calculateSavedItems = this.calculateSavedItems.bind(this);
		this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
		this.resetSelections = this.resetSelections.bind(this);
		this.saveExistingMeal = this.saveExistingMeal.bind(this);
		this.setResetStateToFalse = this.setResetStateToFalse.bind(this);
        this.toggleSaveMenu = this.toggleSaveMenu.bind(this);
    }
	componentDidMount() {
		// Add event listeners for resize and keypress
		window.addEventListener('resize', this.handleWindowSizeChange);
		document.addEventListener("keydown", this.keyPressHandler, false);
    }; 
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.resetSelections();
        }
        if (this.props.data.length > 0 && this.props.preselectedItems != null && !this.state.preselectIsDone) {
            this.calculateSavedItems();
        }
        const hasSelected = document.querySelector(".selected") !== null;
		if (this.state.showSaveBtn !== hasSelected) {
			this.setState({
				showSaveBtn: hasSelected
			});
		}
    }
    calculateSavedItems() {
        let totals = this.state.totals;
        if (this.props.preselectedItems !== null) {
            for (let i=0;i<this.props.preselectedItems.items.length;i++) {
                let id = this.props.preselectedItems.items[i];
                document.getElementById(id).click();
                let thisItem = this.props.data.filter(i => i.id === id);
                
                totals.totalCal += parseFloat(thisItem[0].cals);
                totals.totalFatCal += parseFloat(thisItem[0].fatCal);
                totals.totalFat += parseFloat(thisItem[0].fat);
                totals.totalSatFat += parseFloat(thisItem[0].satFat);
                totals.totalTransFat += parseFloat(thisItem[0].transFat);
                totals.totalChol += parseFloat(thisItem[0].cholesterol);
                totals.totalSodium += parseFloat(thisItem[0].sodium);
                totals.totalCarbs += parseFloat(thisItem[0].carbs);
                totals.totalFiber += parseFloat(thisItem[0].fiber);
                totals.totalSugar += parseFloat(thisItem[0].sugar);
                totals.totalProtein += parseFloat(thisItem[0].protein);
            }
        }
        this.setState({
            totals: totals,
            preselectIsDone: true
        })
    }
	calculateItem(itemId, selected) {
		// Get data for this item
        const thisItem = this.props.data.filter(i => i.id === itemId);
        
		// If not already selected, add item to total
		if (!selected) {
			this.setState({
				totals: {
					totalCal: this.state.totals.totalCal + parseFloat(thisItem[0].cals),
					totalFatCal: this.state.totals.totalFatCal + parseFloat(thisItem[0].fatCal),
					totalFat: this.state.totals.totalFat + parseFloat(thisItem[0].fat),
					totalSatFat: this.state.totals.totalSatFat + parseFloat(thisItem[0].satFat),
					totalTransFat: this.state.totals.totalTransFat + parseFloat(thisItem[0].transFat),
					totalChol: this.state.totals.totalChol + parseFloat(thisItem[0].cholesterol),
					totalSodium: this.state.totals.totalSodium + parseFloat(thisItem[0].sodium),
					totalCarbs: this.state.totals.totalCarbs + parseFloat(thisItem[0].carbs),
					totalFiber: this.state.totals.totalFiber + parseFloat(thisItem[0].fiber),
					totalSugar: this.state.totals.totalSugar + parseFloat(thisItem[0].sugar),
					totalProtein: this.state.totals.totalProtein + parseFloat(thisItem[0].protein)
				}
			})
		}
		// Otherwise subtract from total
		else {
			this.setState({
				totals: {
					totalCal: this.state.totals.totalCal - parseFloat(thisItem[0].cals),
					totalFatCal: this.state.totals.totalFatCal - parseFloat(thisItem[0].fatCal),
					totalFat: this.state.totals.totalFat - parseFloat(thisItem[0].fat),
					totalSatFat: this.state.totals.totalSatFat - parseFloat(thisItem[0].satFat),
					totalTransFat: this.state.totals.totalTransFat - parseFloat(thisItem[0].transFat),
					totalChol: this.state.totals.totalChol - parseFloat(thisItem[0].cholesterol),
					totalSodium: this.state.totals.totalSodium - parseFloat(thisItem[0].sodium),
					totalCarbs: this.state.totals.totalCarbs - parseFloat(thisItem[0].carbs),
					totalFiber: this.state.totals.totalFiber - parseFloat(thisItem[0].fiber),
					totalSugar: this.state.totals.totalSugar - parseFloat(thisItem[0].sugar),
					totalProtein: this.state.totals.totalProtein - parseFloat(thisItem[0].protein)
				}
			})
		}
	}
	handleWindowSizeChange() {
		const currentWidth = this.state.isMobile;
		this.setState({ 
			isMobile: window.innerWidth < 992
		});
		if (currentWidth !== this.state.isMobile) {
			this.resetSelections();
		}
	}
	keyPressHandler(event) {
		// After tab is pressed, enable focus outlines again (removes 'no-outline' class from body)
		if (event.keyCode === 9) {
			document.getElementsByTagName("body")[0].className = "active";
		}
	}
	resetSelections() {
		if (document.getElementsByClassName("selected").length > 0) {
			this.setState({
				resetState: true
			});
		}
		// Go through all selected items and remove their 'selected' class
		// Array.prototype.slice.call() is necessary to make IE work
		//Array.prototype.slice.call(document.querySelectorAll('.selected')).forEach( item => item.className = "menu-select" );
		
		// Reset totals
		this.setState({
			totals: {
				totalCal: 0,
				totalFatCal: 0,
				totalFat: 0,
				totalSatFat: 0,
				totalTransFat: 0,
				totalChol: 0,
				totalSodium: 0,
				totalCarbs: 0,
				totalFiber: 0,
				totalSugar: 0,
				totalProtein: 0
			}
		});
		// Track click in GA
		trackLink('Reset', 'Reset totals');
    }
    saveExistingMeal() {
        let today = new Date();
        let todayString = today.toString();
        if (this.props.preselectedItems) {
            let data = this.props.preselectedItems;
            data.items = [];
            Array.prototype.slice.call(document.querySelectorAll('.selected')).forEach( item => {
                data.items.push(item.id);
            });
            data.lastModified = todayString;
            let db = firebase.firestore(this.props.firebase);
            db.collection("meals").doc(data.id).set(data)
            .then(function() {
				messageBanner("Saved!", 3000, false)
            })
            .catch(function(error) {
				console.error("Error submitting to firebase");
				errorMessageBanner(error);
            });
        }
    }
	scrollToTop() {
		// Scroll to top of page (top = 0)
		setTimeout(function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
		// Track click in GA
		trackLink('Scroll to top', 'Desktop');
	}
	// After MenuItem select state is reverted because of a totals reset, this function is called to reset the resetState
	setResetStateToFalse() {
		this.setState({
			resetState: false
		});
	}
    toggleSaveMenu() {
        this.setState({
            showSaveMeal: !this.state.showSaveMeal
        })
    }
	render() {
		const list = this.props.data;
		const markup = this.props.data.map((item, i) => {
			if (i === 0) {
				return (<tbody key={"menu-break-"+item.id}>
					<MenuBreakItem mobile={this.state.isMobile} item={item} />
					<MenuItem resetFunct={this.setResetStateToFalse} resetState={this.state.resetState} mobile={this.state.isMobile} item={item} update={this.calculateItem} />
				</tbody>)
			}
			else {
				if (item.categorytitle !== list[i-1].categorytitle) {
					return (<tbody key={"menu-break-"+item.id}>
						<MenuBreakItem mobile={this.state.isMobile} item={item} />
						<MenuItem resetFunct={this.setResetStateToFalse} resetState={this.state.resetState} mobile={this.state.isMobile} item={item} update={this.calculateItem} />
					</tbody>)
				}
			}
			return <tbody key={"menu-item-"+item.id}><MenuItem resetFunct={this.setResetStateToFalse} resetState={this.state.resetState} mobile={this.state.isMobile} item={item} update={this.calculateItem} /></tbody>
		});
		return (
			<div className="menu-table">
                {this.props.data.length > 0 && <div className="menu-results remove-margin">
				<table className={this.state.isMobile ? "menu-items table table-striped mobile" : "menu-items table table-striped desktop"}>
					<thead>
						<tr>
							<th scope="col"></th>
							<th scope="col"><div className={this.state.isMobile ? "off-screen" : ""}>Item</div></th>
							{!this.state.isMobile && <th scope="col"><div>Calories</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Calories from Fat</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Fat (g)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Saturated Fat (g)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Trans Fat (g)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Cholesterol (mg)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Sodium (mg)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Carbs (g)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Dietary Fiber (g)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Sugars (g)</div></th>}
							{!this.state.isMobile && <th scope="col"><div>Protein (g)</div></th>}
						</tr>
					</thead>
					{markup} 
				</table>
				{this.state.hasAllergies && <div className="allergies">
					<span title="Contains Egg" className="contains-marker contains-egg"><span className="off-screen">Contains Egg</span></span> = Contains Egg <br/>
					<span title="Contains Fish" className="contains-marker contains-fish"><span className="off-screen">Contains Fish</span></span> = Contains Fish <br/>
					<span title="Contains Milk" className="contains-marker contains-milk"><span className="off-screen">Contains Milk</span></span> = Contains Milk <br/>
					<span title="Contains Shellfish" className="contains-marker contains-shellfish"><span className="off-screen">Contains Shellfish</span></span> = Contains Shellfish <br/>
					<span title="Contains Soy" className="contains-marker contains-soy"><span className="off-screen">Contains Soy</span></span> = Contains Soy <br/>
					<span title="Contains Tree Nuts" className="contains-marker contains-tree"><span className="off-screen">Contains Tree Nuts</span></span> = Contains Tree Nuts <br/>
					<span title="Contains Wheat" className="contains-marker contains-wheat"><span className="off-screen">Contains Wheat</span></span> = Contains Wheat <br/>
				</div>}
				{!this.state.isMobile && <div className="back-to-top"><button type="button" onClick={this.scrollToTop} title="Back to Top" aria-label="Scroll back to top of screen"><i className="fas fa-arrow-up"></i> Back to Top</button></div>}
				</div>}
				{this.props.data.length > 0 && 
				<div className="total-nutrition">
					<div className="table-responsive content-container container-padding">
						<table className={this.state.isMobile ? "table mobile" : "table desktop"}>
							<tbody>
								{!this.state.isMobile &&
									<tr>
										<td><button id="reset-nutrition" className="btn btn-primary reset-button" aria-label="Reset nutrition" onClick={this.resetSelections}>Reset</button></td>
										<td>
                                            {(!this.props.preselectedItems && this.state.showSaveBtn) ? <button id="save-meal-btn" className="btn btn-alt" onClick={this.toggleSaveMenu} ><FontAwesomeIcon icon={faSave} className="mr-5"/> Save</button> : null}
                                            {(this.props.preselectedItems && this.state.showSaveBtn) ? <button id="save-meal-btn" className="btn btn-alt" onClick={this.saveExistingMeal} ><FontAwesomeIcon icon={faSave} className="mr-5"/> Save</button> : null}
                                        </td>
										<td>Total</td>
										<td title="Total calories" id="totalCal" aria-label="Total calories">{this.state.totals.totalCal}</td>
										<td title="Total calories from fat" id="totalFatCal" aria-label="Total calories from fat">{this.state.totals.totalFatCal}</td>
										<td title="Total fat (g)" id="totalFat" aria-label="Total fat (g)">{this.state.totals.totalFat}</td>
										<td title="Total saturated fat (g)" id="totalSatFat" aria-label="Total saturated fat (g)">{this.state.totals.totalSatFat}</td>
										<td title="Total trans fat (g)" id="totalTransFat" aria-label="Total trans fat (g)">{this.state.totals.totalTransFat}</td>
										<td title="Total cholesterol (mg)" id="totalChol" aria-label="Total cholesterol (mg)">{this.state.totals.totalChol}</td>
										<td title="Total sodium (mg)" id="totalSodium" aria-label="Total sodium (mg)">{this.state.totals.totalSodium}</td>
										<td title="Total carbohydrates (g)" id="totalCarbs" aria-label="Total carbohydrates (g)">{this.state.totals.totalCarbs}</td>
										<td title="Total dietary fiber (g)" id="totalFiber" aria-label="Total dietary fiber (g)">{this.state.totals.totalFiber}</td>
										<td title="Total sugars (g)" id="totalSugar" aria-label="Total sugars (g)">{this.state.totals.totalSugar}</td>
										<td title="Total protein (g)" id="totalProtein" aria-label="Total protein (g)">{this.state.totals.totalProtein}</td>
								</tr>}
								{this.state.isMobile &&
									<MobileTotals preselectedItems={this.props.preselectedItems != null} showSaveBtn={this.state.showSaveBtn} saveExistingMeal={this.saveExistingMeal} toggleSaveMenu={this.toggleSaveMenu} buttonclick={this.resetSelections} totals={this.state.totals} />}	
							</tbody>
						</table>
					</div>
				</div>}
                {this.props.data.length > 0 && this.state.showSaveMeal && <SaveMeal menu={this.props.menuseason} toggleModal={this.toggleSaveMenu} user={this.props.user} firebase={this.props.firebase} existingMeal={this.props.preselectedItems}/>}
            </div>
		);
	}
};

export default MenuTable;