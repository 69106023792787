import React from 'react';
import { Redirect } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {errorMessage, trackLink} from '../GlobalFunctions';

class LogIn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            redirect: false,
            email: "",
            password: "",
            showForgotModal: false,
            forgotEmail: "",
            resetSubmitted: false,
            resetSuccess: false,
            refresh: false
        }
        this.signInWithGoogle = this.signInWithGoogle.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleForgotEmailChange = this.handleForgotEmailChange.bind(this);
        this.handleForgotPasswordSubmit = this.handleForgotPasswordSubmit.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }
    signInWithGoogle() {
        let cmp = this;
        trackLink("Login Module", "Google Sign-In Button Clicked");
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(function() {
            var provider = new firebase.auth.GoogleAuthProvider();
            return firebase.auth().signInWithPopup(provider);
        }).then(fb => {
            cmp.setState({
                redirect: true
            });
            localStorage.setItem('CLOSED_NOTIFICATION', true);
            trackLink("Login Module", "Google Sign-In Success");
        }).catch(function(error) {
            console.log(error);
            trackLink("Login Module", "Google Sign-In Error - " + error.message);
            document.getElementById("firebase-login-form").appendChild(errorMessage(error));
        });
    }
    handleForgotEmailChange(e) {
        this.setState({forgotEmail: e.target.value});
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value});
    }
    handlePasswordChange(e) {
        this.setState({password: e.target.value});
    }
    handleLoginSubmit(e) {
        e.preventDefault();
        let cmp = this;
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(data => {
            cmp.setState({
                redirect: true
            });
            localStorage.setItem('CLOSED_NOTIFICATION', true);
            trackLink("Login Module", "Email Sign-In");
        }).catch(function(error) {
            console.log(error);
            trackLink("Login Module", "Email Sign-In Error - " + error.message);
            document.getElementById("firebase-login-form").appendChild(errorMessage(error));
        });
    }
    handleForgotPasswordSubmit(e) {
        e.preventDefault();
        let email = this.state.forgotEmail;
        let cmp = this;
        firebase.auth().sendPasswordResetEmail(email).then(function(res) {
            trackLink("Login Module", "Forgot Password Success");
            cmp.setState({
                resetSubmitted: true,
                resetSuccess: true
            });
        }).catch(function(error) {
            console.log(error);
            trackLink("Login Module", "Forgot Password Error - " + error.message);
            document.getElementById("firebase-reset-pw-form").appendChild(errorMessage(error));
        });
    }
    toggleModal() {
        if (this.state.resetSubmitted) {
            this.setState({
                refresh: true
            });
        }
        this.setState({
            showForgotModal: !this.state.showForgotModal
        });
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to="/"/>
        }
        else if (this.state.refresh) {
            window.location.reload();
        }
        return (
            <div className="login-module">
                <form id="firebase-login-form" className="mb-20" onSubmit={this.handleLoginSubmit}>
                    <label htmlFor="login-email">Email</label>
                    <input id="login-email" name="email" type="text" value={this.state.email} onChange={this.handleEmailChange}/>
                    <label htmlFor="login-password">Password</label>
                    <input id="login-password" name="password" type="password" value={this.state.password} onChange={this.handlePasswordChange}/>
                    <div className="forgot-password right">
                        <button type="button" onClick={this.toggleModal}>Forgot password?</button>
                    </div>
                    <button id="firebase-login-submit" type="submit" className="btn btn-alt">Log In</button>
                </form>
                <p>or</p>
                <button className="btn p-0" onClick={this.signInWithGoogle}><img style={{maxWidth: 200}} alt="Sign In with Google" src="/images/icons/signin-google.png" /></button>
                {this.state.showForgotModal && <div className="modal-bg">
                    <div className="modal-container reset-pw">
                        <button className="btn-close" onClick={this.toggleModal}>
                            <FontAwesomeIcon icon={faTimes} className="close-icon" />
                            <span className="off-screen">Close modal</span>
                        </button>
                        <h2>Reset Password</h2>
                        <p>Enter your account email address below and submit to be e-mailed a link to reset your password.</p>
                        {!this.state.resetSubmitted &&<form id="firebase-reset-pw-form" onSubmit={this.handleForgotPasswordSubmit}>
                            <label htmlFor="forgot-email">Email</label>
                            <input id="forgot-email" name="email" type="text" value={this.state.forgotEmail} onChange={this.handleForgotEmailChange}/>
                            <button id="firebase-reset-pw-submit" type="submit" className="btn btn-alt">Reset Password</button>
                        </form>}
                        {this.state.resetSubmitted && this.state.resetSuccess && <div className="form-status mt-10">
                            <FontAwesomeIcon icon={faCheckCircle} className="mb-10 blue-text icon" />
                            Reset password link sent! Please check your email to update your password.
                        </div>}
                        {this.state.resetSubmitted && !this.state.resetSuccess && <div className="form-status mt-10">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mb-10 red-text icon" />
                            Oops, there was an error. Please refresh the page and try again soon.
                        </div>}
                    </div>
                </div>}
            </div>
        );
    }
}

export default LogIn;