import React from 'react';
class GoogleAd extends React.Component {
	componentDidMount() {
		(window.adsbygoogle = window.adsbygoogle || []).push({});
    }; 
	render() {
		return (
            <div>
                <ins className='adsbygoogle'
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-7607325002121806"
                    data-ad-slot="4205141266"
                    data-ad-format='auto'
                    data-full-width-responsive="true"
                    data-adtest="on" />
            </div>
		);
	}
};

export default GoogleAd;