import React from 'react';
import { trackLink } from '../GlobalFunctions';

class MenuBreakItem extends React.Component {
	constructor(props) {
		super(props);
		this.scrollToTop = this.scrollToTop.bind(this);
	}
	
	// Scroll to top of page
	scrollToTop() {
		setTimeout(function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
		
		// Submit click event to Google Analytics
		trackLink('Scroll to top', 'Mobile - ' + this.props.item.categorytitle);
	}
	render() {
		return (
			<tr className="menu-break">
				<td colSpan={this.props.mobile ? "2" : "14" }>
					<div>{this.props.item.categorytitle}</div>{this.props.mobile && <div className="back-to-top"><button type="button" onClick={this.scrollToTop} title="Back to Top" aria-label="Scroll back to top of screen"><i className="fas fa-arrow-up"></i></button></div>}
				</td>
			</tr>
		);
	}
};

export default MenuBreakItem;