import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { trackLink } from '../GlobalFunctions';

class Nav extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            showAcctOptions: false
        }
        this.logOut = this.logOut.bind(this);
        this.toggleAcctOptions = this.toggleAcctOptions.bind(this);
    }
    logOut() {
        let cmp = this;
        firebase.auth().signOut().then(function() {
            cmp.props.updateSignIn(null, null);
        }).catch(function(error) {
            console.log(error);
        });
        trackLink("Nav", "Log Out")
    }
    toggleAcctOptions() {
        this.setState({
            showAcctOptions: !this.state.showAcctOptions
        })
    }
	render() {
		return (
			<nav className="nav">
                {this.props.loaded && <div className="content-container container-padding">
                    <div className="title"><a onClick={() => trackLink("Nav", "Main Home")}href="/">Honeygrow Nutrition Calculator</a></div>
                    {this.props.showMenus && <div className="date">
                        <select id="menu-season-select" className="no-style" onChange={(e) => {this.props.updateSeason(e)}} value={this.props.menuseason}>
                            <option value="summer-2020">Summer 2020</option>
                            <option value="spring-2020">Spring 2020</option>
                            <option value="winter-2020">Winter 2020</option>
                            <option value="fall-2019">Fall 2019</option>
                            <option value="summer-2019">Summer 2019</option>
                            <option value="winter-2019">Winter 2019</option>
                            <option value="fall-2018">Fall 2018</option>
                        </select>
                    </div>}
                    {this.props.signedIn && 
                        <div className="account-options flex-col-center">
                            <button className="account-toggle flex-col-center" type="button" onClick={this.toggleAcctOptions}>
                                {this.props.user.photoURL ? <img className="account-avatar" alt={this.props.user.displayName + " photo"} src={this.props.user.photoURL}/> : <FontAwesomeIcon icon={faUserCircle} size="1x"/>}
                            </button>
                            <ul className="account-options-links">
                                <li className="blue-text">{this.props.user.displayName}</li>
                                <li><a onClick={() => trackLink("Nav", "Dropdown - Home")} href="/">Home</a></li>
                                <li><a onClick={() => trackLink("Nav", "Dropdown - Saved Meals")} href="/account">Saved Meals</a></li>
                                <li><button onClick={this.logOut} >Log Out</button></li>
                            </ul>
                        </div>
                    }
                    {!this.props.signedIn && <a onClick={() => trackLink("Nav", "Log In")} className="log-in-out" href="/login">Log In</a>}

                </div>}
            </nav>
		);
	}
};

export default Nav;