import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import MenuTable from '../components/MenuTable';
import Unauthorized from '../components/Unauthorized';
import { messageBanner, errorMessageBanner, trackLink } from '../GlobalFunctions';

class MealPage extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            cmpHasUser: false,
            menu: [],
			isMobile: window.innerWidth < 992,
			totals: {
				totalCal: 0,
				totalFatCal: 0,
				totalFat: 0,
				totalSatFat: 0,
				totalTransFat: 0,
				totalChol: 0,
				totalSodium: 0,
				totalCarbs: 0,
				totalFiber: 0,
				totalSugar: 0,
				totalProtein: 0
			},
            resetState: false,
            item: null,
            firstLoad: true,
            invalidDoc: false,
            isCorrectUser: true,
            editNameActive: false,
            newMealName: ""
        }
        this.getData = this.getData.bind(this);
        this.setUpTable = this.setUpTable.bind(this);
        this.handleMealNameSubmit = this.handleMealNameSubmit.bind(this);
        this.handleMealNameChange = this.handleMealNameChange.bind(this);
        this.toggleNameEdit = this.toggleNameEdit.bind(this);
    }
	componentDidMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
        if (this.props.user !== null)
            this.getData();
    }; 
    getData() {
        let id  = document.location.href.match(/\/meal\/(.*)($|\/)/)[1];
        const cmp = this;
        const userId = this.props.user.uid;
        let db = firebase.firestore(this.props.firebase);
        db.collection("meals").doc(id).get().then(function(doc) {
            if (doc.exists) {
                let data = doc.data();
                data.id = id;
                if (data.user === userId) {
                    cmp.setState({
                        cmpHasUser: true,
                        id: id,
                        item: data,
                        newMealName: data.title
                    }, () => { 
                        cmp.setUpTable();
                    });
                }
                else {
                    console.log("Error: Invalid User");
                    cmp.setState({
                        cmpHasUser: false,
                        firstLoad: false,
                        isCorrectUser: false
                    });
                }
            } else {
                console.log("Error. No such document: " + id);
                cmp.setState({
                    invalidDoc: true,
                    firstLoad: false
                });
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
            cmp.setState({
                invalidDoc: true,
                firstLoad: false
            });
        });
        
    }
    setUpTable() {
        const season = this.state.item.menu;
        const storedData = JSON.parse(localStorage.getItem(season));
        const currentCat = this.state.item.category;
        // Used data from local storage instead of fetching if possible
        if (storedData !== null && storedData[currentCat]) {
            this.setState({
                menu: storedData[currentCat]
            });
        }
        else {
            fetch("/files/" + season + ".json")
            .then( (response) => {
                return response.json() })
            .then( (json) => { 
                // Sort data into respective categories
                for (var i=0;i<json.menuitems.length;i++) {
                    if (json.menuitems[i].category === currentCat)
                        this.state.menu.push(json.menuitems[i]);
                }
            }).then(()=>{ 
                this.setState({
                    firstLoad: false
                });
            });
        }
    }
    handleMealNameChange(e) {
        this.setState({
            newMealName: e.target.value
        });
    }
    handleMealNameSubmit(e) {
        e.preventDefault();
        let item = this.state.item;
        item.title = this.state.newMealName;
        this.setState({
            item: item,
            editNameActive: false
        }, () =>{
            // Save Meal
            let today = new Date();
            let todayString = today.toString();
            item.lastModified = todayString;
            let db = firebase.firestore(this.props.firebase);
            db.collection("meals").doc(item.id).set(item)
            .then(function() {
				messageBanner("Saved!", 3000, false);
            })
            .catch(function(error) {
				console.error("Error submitting to firebase");
				errorMessageBanner(error);
            });
        });
    }
    toggleNameEdit() {
        this.setState({
            editNameActive: !this.state.editNameActive,
            newMealName: this.state.item.title
        });
    }
	render() {
        const authorized = this.props.isLoggedIn && this.state.isCorrectUser;
		return (
			<div>
                {/* <div id="spinner"><div className="loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> */}
                {!authorized && !this.state.invalidDoc && <Unauthorized />}
                {(!this.state.firstLoad && this.state.invalidDoc) &&
                    <div>
                        <h1 id="heading" className="mb-10">Meal Could Not Be Found</h1>
                        <p>This meal does not exist. Please try going back to your account page to see your current meal list.</p>
                        <a onClick={() => trackLink("Meal Page", "Meal not found - Account Btn")} className="btn btn-alt" href="/account">Account</a>
                        <a onClick={() => trackLink("Meal Page", "Meal not found - Home Btn")} className="btn btn-alt ml-10" href="/">Back to Home</a>
                    </div>
                }
                {(authorized && this.state.item) && <div>
                    {!this.state.editNameActive && <h1 id="heading">{this.state.item.title} <button id="edit-meal-name" title="Edit meal name" type="button" className="title-icon" onClick={this.toggleNameEdit}><FontAwesomeIcon icon={faPen}/><span className="off-screen">Edit meal name</span></button></h1>}
                    {this.state.editNameActive && <form className="flex center name-edit" onSubmit={this.handleMealNameSubmit}>
                        <input type="text" value={this.state.newMealName} onChange={this.handleMealNameChange}/> 
                        <div className="vertical-center mt-10 mb-10 ml-5">
                            <button title="Save new meal name" type="submit" className="btn-small btn-alt">
                                <FontAwesomeIcon icon={faCheck}/><span className="off-screen">Save new meal name</span>
                            </button>
                            <button title="Cancel meal name edit" type="button" className="btn-small btn-primary mt-2" onClick={this.toggleNameEdit}>
                                <FontAwesomeIcon icon={faTimes}/><span className="off-screen">Cancel meal name edit</span>
                            </button>
                        </div>
                    </form>}
                    <MenuTable user={this.props.user} data={this.state.menu} preselectedItems={this.state.item} firebase={this.props.firebase}/>
                </div>}
            </div>
		);
	}
};

export default MealPage;