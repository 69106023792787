import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import NutritionPage from './pages/NutritionPage';
import AccountPage from './pages/AccountPage';
import MealPage from './pages/MealPage';
import LogInPage from './pages/LogInPage';
import Nav from './components/Nav';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactMe from './pages/ContactMe';
import firebase from 'firebase/app';
import 'firebase/auth';
import './style.scss';
import { messageBanner, trackLink } from './GlobalFunctions';
import GoogleAd from './components/GoogleAd';
  
var app = firebase.initializeApp({ 
    apiKey: "AIzaSyD6XLEYVKFOoGhFABOp58ckcO6A2GLTa7w",
    authDomain: "hg-nutrition.firebaseapp.com",
    databaseURL: "https://hg-nutrition.firebaseio.com",
    projectId: "hg-nutrition",
    storageBucket: "hg-nutrition.appspot.com",
    messagingSenderId: "896103448936",
    appId: "1:896103448936:web:fe8511f5b969d104fad6dd"
});
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            loggedIn: null,
            user: {uid: null},
            menuseason: "summer-2020",
            itemIsSelected: false,
            firstLoad: false
        }
		this.updateSeason = this.updateSeason.bind(this);
        this.updateSignIn = this.updateSignIn.bind(this);
    }
	componentDidMount() {
		// Remove no-script class from body on page load
        document.getElementsByTagName("body")[0].className = "active no-outline";
        let cmp = this;
        firebase.auth().onAuthStateChanged(user => {
            //var token = user.credential.accessToken;
            this.updateSignIn(user);
            cmp.setState({
                firstLoad: true,
                loggedIn: user !== null
            });
        });
        if (!localStorage.getItem('CLOSED_NOTIFICATION')) {
            let message = "<strong>New feature alert!</strong> Save your meals and return to them anytime. <a href=\"/login\">Create an account here!</a>";
            messageBanner(message, 0, true);
        }
    }; 
    updateSeason(event) {
        trackLink("Update Season", event.target.value);
		this.setState({
			menuseason: event.target.value
		});
	}
    updateSignIn(user) {
        let isLoggedIn = user !== null;
        this.setState({
            loggedIn: isLoggedIn,
            user: user
        });
    }
	render() {
		return (
            <Router>
                <Switch>
                    <Route exact path='/'>
                        <Nav showMenus={true} updateSignIn={this.updateSignIn} loaded={this.state.firstLoad} signedIn={this.state.loggedIn} updateSeason={this.updateSeason} menuseason={this.state.menuseason} user={this.state.user} firebase={app} />
                    </Route>
                    <Route path='/*'>
                        <Nav showMenus={false} updateSignIn={this.updateSignIn} loaded={this.state.firstLoad} signedIn={this.state.loggedIn} updateSeason={this.updateSeason} menuseason={this.state.menuseason} user={this.state.user} firebase={app} />
                    </Route>
                </Switch>
                
				<div className="content-container container-padding">
                    <Switch>
                        <Route exact path="/login">
                            <LogInPage updateSignIn={this.updateSignIn} />
                        </Route>
                        <Route exact path="/account">
                            {this.state.loggedIn !== null && <AccountPage firebase={app} user={this.state.user} />}
                            {this.state.loggedIn === false && <Redirect to="/" />}
                        </Route>
                        <Route exact path="/meal/:id">
                            {this.state.loggedIn !== null && <MealPage firebase={app} user={this.state.user} isLoggedIn={this.state.loggedIn} />}
                        </Route>
                        <Route exact path="/privacy">
                            <PrivacyPolicy />
                        </Route>
                        <Route exact path="/contact">
                            <ContactMe />
                        </Route>
                        <Route path="/">
                            <NutritionPage firebase={app} user={this.state.user} token={this.state.token} menuseason={this.state.menuseason}/>
                        </Route>
                    </Switch>
                    <GoogleAd />
                </div>
                <Footer />
            </Router>
		);
	}
};

export default App;