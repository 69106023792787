import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { trackLink } from '../GlobalFunctions';

class SaveMeal extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            mealName: "",
            formSubmitted: false
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }
    handleForm(e) {
        e.preventDefault();
        let today = new Date();
        let todayString = today.toString();
        let data = {
            title: this.state.mealName,
            menu: this.props.menu,
            category: document.querySelector(".current-cat").id,
            items: [],
            user: this.props.user.uid,
            created: todayString,
            lastModified: todayString,
            private: true
        }
        Array.prototype.slice.call(document.querySelectorAll('.selected')).forEach( item => {
            data.items.push(item.id);
        });
        let cmp = this;
        let db = firebase.firestore(this.props.firebase);
        db.collection("meals").add(data)
        .then(function() {
            cmp.setState({
                formSubmitted: true
            });
        })
        .catch(function(error) {
            console.error("Error submitting to firebase: ", error);
        });
    }
    handleInput(e) {
        this.setState({mealName: e.target.value});
    }
	render() {
		return (
			<div className="modal-bg">
                <div className="modal-container">
                    <button className="btn-close" onClick={this.props.toggleModal}>
                        <FontAwesomeIcon icon={faTimes} className="close-icon" />
                        <span className="off-screen">Close modal</span>
                    </button>
                    <h3>Save Your Meal</h3>
                    {this.props.user && !this.state.formSubmitted && <form id="save-meal-form" onSubmit={this.handleForm}>
                        <label htmlFor="meal-name">Name: </label>
                        <input type="text" onChange={this.handleInput} value={this.state.mealName} />
                        <button className="btn btn-alt" type="submit" disabled={this.state.mealName === ""}>Save</button>
                    </form>}
                    {this.props.user && this.state.formSubmitted && <div>
                        <p>Meal Saved!</p>
                        <div>
                            <button onClick={this.props.toggleModal} className="btn btn-alt" type="button">Back</button>
                            <a onClick={() => trackLink("Saved Meal", "View Meals")} className="ml-10 btn btn-alt" type="button" href="/account">View Meals</a>
                        </div>
                    </div>}
                    {!this.props.user && <div>
                        <p>Please log in to use this functionality.</p>
                        <div>
                            <a onClick={() => trackLink("Save Meal", "Log In")} className="btn btn-alt" type="button" href="/login">Log In</a>
                        </div>
                    </div>}
                </div>
            </div>
		);
	}
};

export default SaveMeal;