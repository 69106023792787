import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import MenuTable from '../components/MenuTable';
import { trackLink } from '../GlobalFunctions';

require('es6-promise').polyfill();
require('isomorphic-fetch');

smoothscroll.polyfill();

class NutritionPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			cyoSalad: [],
			cyoStirfry: [],
			honeybar: [],
			featured: [],
			currentCategory: '',
			currentCategoryTitle: '',
			headingTop: 0
		}
        this.changeData = this.changeData.bind(this);
	}
	componentDidMount() {
		// Set the scroll to position (heading div)
		this.setState({
			headingTop: document.getElementById('heading').getBoundingClientRect().top,
		})
		this.fetchMenuData(this.props.menuseason);
		
		
	}; 

	// make sure to remove the listeners when unmounted
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
		document.removeEventListener("keydown", this.keyPressHandler, false);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.menuseason !== this.props.menuseason) {
			this.fetchMenuData(this.props.menuseason);
		}
	}
	changeData(datasrc) {
		// Reset the total
		//resettotals();
		// Change data state to category's data
		if (datasrc !== "") {
			let catTitle = "";
	
			// Change data state to category's data
			if (datasrc === "featured") {
				catTitle = "Featured Nutrition";
			}
			else if (datasrc === "cyoSalad") {
				catTitle = "CYO Salad Nutrition";
			}
			else if (datasrc === "cyoStirfry") {
				catTitle = "CYO Stir Fry Nutrition";
			}
			else if (datasrc === "honeybar") {
				catTitle = "CYO Honeybar " + (this.state.hasJuice ? "and Juices " : "") + "Nutrition"	// Juices only available in some menus
			}

			this.setState({
				data: this.state[datasrc],
				hasAllergies: this.state[datasrc][0].wheat != null,
				currentCategory: datasrc,
				currentCategoryTitle: catTitle
			})

			trackLink("Category Nav", datasrc);
		}
		
		// Only scroll down to menu if calculator is shown (aka not on page load)
		if (this.state.showCalculator) {
			// Scroll to menu listing
			const scrollto = this.state.headingTop - 60;
			setTimeout(function () {
				window.scrollTo({
					top: scrollto,
					behavior: "smooth"
				});
			});
		}
		
		// Set show calculator state so that class will change on category button container
		this.setState({
			showCalculator: true
		})
		
	}
	fetchMenuData(season) {
		const storedData = JSON.parse(localStorage.getItem(season));
		const currentCat = this.state.currentCategory;
		// Used data from local storage instead of fetching if possible
		if (storedData != null) {
			this.setState(storedData,() => { this.changeData(currentCat) });
		}
		else {
			fetch("/files/" + season + ".json")
			.then( (response) => {
				return response.json() })
			.then( (json) => { 
				this.setState({
					cyoSalad: [],
					cyoStirfry: [],
					honeybar: [],
					featured: []
				});
				// Sort data into respective categories
				for (var i=0;i<json.menuitems.length;i++) {
					this.state[json.menuitems[i].category].push(json.menuitems[i]);
				}
				
				let hasJuice = this.state.honeybar[this.state.honeybar.length-1].categorytitle === "Juice";	// Check last honeybar item to see if juices are on this menu
				this.setState({
					hasJuice: hasJuice
				});
				localStorage.setItem(season,JSON.stringify({	// Store organized data in local storage for faster loading in the future
					data: [],
					cyoSalad: this.state.cyoSalad,
					cyoStirfry: this.state.cyoStirfry,
					honeybar: this.state.honeybar,
					featured: this.state.featured,
					hasJuice: hasJuice
				}))
				this.changeData(this.state.currentCategory);
			})
		}
	}
	render() {
		return (
			<div>
				<div className="instructions">
					{this.props.user != null ? <p>Hi, {this.props.user.displayName}!</p> : ""}
					<p>This nutrition calculator can be used to calculate the total nutrition for your Honeygrow meal.</p>
					<p>First select a menu category: </p>
				</div>
				<div className={this.state.showCalculator ? "menu-options remove-margin open" : "menu-options remove-margin"}>
					<button title="Featured Salads" id="featured" className={this.state.currentCategory === "featured" ? "current-cat" : ""} onClick={() => this.changeData('featured')}>
						<div className="menu-category" style={{backgroundImage:"url('/images/featured.jpg')"}}>
							<h2>Featured</h2>
						</div>
					</button>
					<button title="Create Your Own Salad" id="cyoSalad" className={this.state.currentCategory === "cyoSalad" ? "current-cat" : ""} onClick={() => this.changeData('cyoSalad')}>
						<div className="menu-category" style={{backgroundImage:"url('/images/salad.jpg')"}}>
							<h2>CYO Salad</h2>
						</div>
					</button>
					<button title="Create Your Own Stir Fry" id="cyoStirfry" className={this.state.currentCategory === "cyoStirfry" ? "current-cat" : ""} onClick={() => this.changeData('cyoStirfry')}>
						<div className="menu-category" style={{backgroundImage:"url('/images/noodles.jpg')"}}>
							<h2>CYO Stir&nbsp;Fry</h2>
						</div>
					</button>
					<button title="Create Your Own Honeybar" id="honeybar" className={this.state.currentCategory === "honeybar" ? "current-cat" : ""} onClick={() => this.changeData('honeybar')}>
						<div className="menu-category" style={{backgroundImage:"url('/images/yogurt.jpg')"}}>
							<h2>CYO Honeybar {this.state.hasJuice ? "and Juices" : ""}</h2>
						</div>
					</button>
				</div>
				<h1 id="heading">{this.state.currentCategoryTitle}</h1>
				<MenuTable menuseason={this.props.menuseason} user={this.props.user} data={this.state.data} firebase={this.props.firebase}/>
			</div>
		)
	}
};

export default NutritionPage;