import React from 'react';
import { trackLink } from '../GlobalFunctions';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: false
		}
	}
	render() {
		return (
            <footer>
                <div className="content-container">
                    <div className="disclaimer">
                        <p>Current site data is from the <a rel="noopener noreferrer" onClick={() => trackLink("Footer","Honeygrow Summer 2020 menu link")} href="https://www.honeygrow.com/menu/" target="_blank">Honeygrow website</a>. Calorie counts are based on “light” portions of dressings + sauces. 2,000 calories a day is used for general nutrition advice, but calorie needs may vary. Additional nutrition information available upon request at the restaurant. If you have a severe food allergy or sensitivity, please notify an ambassador or manager in the restaurant. Although some items do not contain any particular allergens, this can not be guaranteed. All stir-fries are made with a small amount of non-GMO garlic oil. </p>
                        <p>This site is not affiliated, associated, authorized, endorsed by, or in any way officially connected with Honeygrow. The official Honeygrow website can be found at <a rel="noopener noreferrer" onClick={() => trackLink("Footer","honeygrow.com")} href="https://www.honeygrow.com/" target="_blank">honeygrow.com</a>. Just a nerdy web developer working on her React skills. View <a onClick={() => trackLink("Footer","Privacy")} href="/privacy">privacy policy</a>.</p>
						<p>Want to get in touch? <a onClick={() => trackLink("Footer","Contact")} href="/contact">Contact me</a>!</p>
                    </div>
                </div>
            </footer>
		);
	}
};

export default Footer;