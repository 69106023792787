import React from 'react';
import { trackLink } from '../GlobalFunctions';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div>
                <h1 className="mb-20">Privacy Policy</h1>
                <p>This web app uses Google Firebase for data storage and user authentication. Your personal data is not used anywhere else or given to any other parties. Please review the <a onClick={() => trackLink("Privacy Policy", "Cloud Terms")} href="https://cloud.google.com/terms/" target="_blank" rel="noopener noreferrer">Google Cloud terms of service</a> for more information.</p>
            </div>
        );
    }
}

export default PrivacyPolicy;