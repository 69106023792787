import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { trackLink } from '../GlobalFunctions';

class ContactMe extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            name: "",
            email: "",
            message: "",
            formSubmitted: false,
            status: 0
        }
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    handleNameChange(e) {
        this.setState({name: e.target.value});
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value});
    }
    handleMessageChange(e) {
        this.setState({message: e.target.value});
    }
    handleFormSubmit(e) {
        e.preventDefault();
        document.getElementById("contact-me-email").setAttribute("disabled", true);
        var formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("message", this.state.message);

        fetch("/files/contact.php", {
            method: "POST", 
            body: formData
        }).then(res => {
            this.setState({
                formSubmitted: true,
                status: res.status
            });
            trackLink("Contact Form Submitted","Success");
        }).catch((error) => {
            console.log(error);
            this.setState({
                formSubmitted: true
            })
            trackLink("Contact Form Submitted","Failure - " + error.message);
        });
    }
    render() {
        let formIsSuccess = 200 <= this.state.status && this.state.status < 300;
        return (
            <div>
                <h1 className="mb-20">Contact</h1>
                <p>Fill out the form below to drop a comment, complaint, suggestion, whatever!</p>
                {!this.state.formSubmitted && <form id="contact-me-form" onSubmit={this.handleFormSubmit}>
                    <label htmlFor="contact-me-name">Name</label>
                    <input id="contact-me-name" name="name" type="text" onChange={this.handleNameChange} required/>
                    <label htmlFor="contact-me-email">Email</label>
                    <input id="contact-me-email" name="email" type="email" onChange={this.handleEmailChange} required/>
                    <label htmlFor="contact-me-message">Message</label>
                    <textarea id="contact-me-message" name="message" onChange={this.handleMessageChange} required/>
                    <button id="contact-me-email" className="btn btn-alt mt-20" name="submit" type="submit">Send</button>
                </form>}
                {this.state.formSubmitted && formIsSuccess && <div className="form-status">
                    <FontAwesomeIcon icon={faCheckCircle} className="mb-10 blue-text icon" />
                    Submitted! Thank you for your feedback.
                </div>}
               {this.state.formSubmitted && !formIsSuccess && <div className="form-status">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mb-10 red-text icon" />
                    Oops, there was an error. Please refresh the page and try again soon.
                </div>}
            </div>
        );
    }
}

export default ContactMe;