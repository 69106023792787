import React from 'react';
import AnimateHeight from 'react-animate-height';

class MobileTotals extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			height: 0,
			icon: "fas fa-angle-up"
		}
        this.toggleAccordion  = this.toggleAccordion.bind(this);
	}
	
	// Toggle accordion after click
	toggleAccordion(e) {
		// Prevent default link action
		e.preventDefault();
		
		// Toggle accordion height and font awesome icon
		this.setState({
			height: this.state.height === 0 ? 'auto' : 0,
			icon: this.state.height === 0 ? "fas fa-angle-down" : "fas fa-angle-up"
		});
		
	}
	render() {
		return (
			<tr>
				<td><button className="btn-small btn-primary reset-button" aria-label="Reset nutrition" onClick={this.props.buttonclick}>Reset</button></td>
				<td>
					{(!this.props.preselectedItems && this.props.showSaveBtn) ? <button className="btn-small btn-alt" onClick={this.props.toggleSaveMenu} >Save</button> : null}
					{(this.props.preselectedItems && this.props.showSaveBtn) ? <button className="btn-small btn-alt" onClick={this.props.saveExistingMeal} >Save</button> : null}
				</td>
				<td onClick={(e) => this.toggleAccordion(e)}><button className="more-info" key="final-counts-mobile"><i className={this.state.icon}></i></button><div>Total Calories: <span id="totalCal">{this.props.totals.totalCal}</span></div>
					<AnimateHeight duration={ 500 } height={ this.state.height }>
					<div className="accordion">
						<p><strong>Calories from fat:</strong> <span id="totalFatCal">{this.props.totals.totalFatCal}</span></p>
						<p><strong>Fat (g):</strong> <span id="totalFat">{this.props.totals.totalFat}</span></p>
						<p><strong>Saturated fat (g):</strong> <span id="totalSatFat">{this.props.totals.totalSatFat}</span></p>
						<p><strong>Trans fat (g):</strong> <span id="totalTransFat">{this.props.totals.totalTransFat}</span></p>
						<p><strong>Cholesterol (mg):</strong> <span id="totalChol">{this.props.totals.totalChol}</span></p>
						<p><strong>Sodium (mg):</strong> <span id="totalSodium">{this.props.totals.totalSodium}</span></p>
						<p><strong>Carbohydrates (g):</strong> <span id="totalCarbs">{this.props.totals.totalCarbs}</span></p>
						<p><strong>Sugar (g):</strong> <span id="totalSugar">{this.props.totals.totalSugar}</span></p>
						<p><strong>Dietary fiber (g):</strong> <span id="totalFiber">{this.props.totals.totalFiber}</span></p>
						<p><strong>Protein (g):</strong> <span id="totalProtein">{this.props.totals.totalProtein}</span></p>
					</div>
					</AnimateHeight>
				</td>
			</tr>
		);
	}
};

export default MobileTotals;