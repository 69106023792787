import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import LogIn from '../components/LogIn';
import {errorMessage, trackLink} from '../GlobalFunctions';

class LogInPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            showSignUp: false
        }
        this.createFirebaseAccount = this.createFirebaseAccount.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
    }
    createFirebaseAccount() {
        let cmp = this;
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(function(data) {
            firebase.auth().currentUser.updateProfile({
                displayName: cmp.state.firstname + " " + cmp.state.lastname,
                firstName: cmp.state.firstname,
                lastName: cmp.state.lastname
            }).then(function() {
                // Success
            }).catch(function(error) {
                console.log(error)
                trackLink("Login Page", "Account Update Error - " + error.message);
            });
            trackLink("Login Page", "Account Created - Email");
            document.getElementById("firebase-create-login").innerHTML = "<p class=\"blue-text\">Account created!</p>";
        }).catch(function(error) {
            trackLink("Login Page", "Account Creation Error - " + error.message);
            document.getElementById("firebase-create-login-form").appendChild(errorMessage(error));
            document.getElementById("create-login-submit").removeAttribute("disabled");
        });
    }
    handleEmailChange(e) {
        this.setState({email: e.target.value});
    }
    handlePasswordChange(e) {
        this.setState({password: e.target.value});
    }
    handleFirstNameChange(e) {
        this.setState({firstname: e.target.value});
    }
    handleLastNameChange(e) {
        this.setState({lastname: e.target.value});
    }
    handleFormSubmit(e) {
        e.preventDefault();
        document.getElementById("create-login-submit").setAttribute("disabled", true);
        this.createFirebaseAccount();
    }
    toggleForm(e) {
        let login = document.getElementsByClassName("account-login")[0];
        let signup = document.getElementsByClassName("account-create")[0];

        if (e.target.classList.contains("account-login-button")) {
            login.classList.add("hidden-on-mobile");
            signup.classList.remove("hidden-on-mobile");
            trackLink("Login Page", "Mobile switch to sign up");
        }
        else {
            signup.classList.add("hidden-on-mobile");
            login.classList.remove("hidden-on-mobile");
            trackLink("Login Page", "Mobile switch to login");
        }
    }
	render() {
        return <div>
            <h1 className="mb-10">Account Log In</h1>
            <div className="login-page">
                <div className="account-create hidden-on-mobile">
                    <h2>Create Account</h2>
                    <p>By creating a HG Nutrition account, you can create and save meals!</p>
                    <div id="firebase-create-login">
                        <form id="firebase-create-login-form" onSubmit={this.handleFormSubmit}>
                            <label htmlFor="create-login-firstname">First Name</label>
                            <input id="create-login-firstname" name="first-name" type="text" value={this.state.firstname} onChange={this.handleFirstNameChange} required/>
                            <label htmlFor="create-login-lastname">Last Name</label>
                            <input id="create-login-lastname" name="last-name" type="text" value={this.state.lastname} onChange={this.handleLastNameChange} required/>
                            <label htmlFor="create-login-email">Email</label>
                            <input id="create-login-email" name="email" type="email" value={this.state.email} onChange={this.handleEmailChange} required/>
                            <label htmlFor="create-login-password">Password</label>
                            <input id="create-login-password" name="password" type="password" value={this.state.password} onChange={this.handlePasswordChange} required/>
                            <button id="create-login-submit" type="submit" className="btn btn-alt">Create Account</button>
                        </form>
                        <div className="mobile-only">
                            <p className="mb-5 mt-40">Already have an account?</p>
                            <button className="account-create-button btn btn-primary" type="button" onClick={this.toggleForm}>Log In</button>
                        </div>
                    </div>
                </div>
                <div className="account-login">
                    <h2>Log In</h2>
                    <LogIn />
                    <div className="mobile-only">
                        <p className="mb-5 mt-40">Don't have an account?</p>
                        <button className="account-login-button btn btn-primary" type="button" onClick={this.toggleForm}>Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
	}
};

export default LogInPage;