import ReactGA from 'react-ga';

ReactGA.initialize('UA-129395828-1');
ReactGA.pageview('/');

// Track a link in GA
export function trackLink(action, label) {
    ReactGA.event({
        category: 'Clicks',
        action: action,
        label: label
    });
}
// Returns div with error message styling
export function errorMessage(error) {
    console.log(error);
    let errors = document.getElementsByClassName("error-text");
    for (let i=0;i<errors.length;i++) {
        errors[0].remove();
    }
    let div = document.createElement("div");
    div.classList.add("error-text");
    div.classList.add("mt-20");
    div.innerHTML = "<p class=\"red-text\">" + error.message.replace("The user may have been deleted.","") + "</p>";
    return div;
}
// Creates error message banner that fades out when clicked
export function errorMessageBanner(error) {
    console.log(error);
    trackLink("Error Banner", error.message);
    // Create notification that there was an error
    let div = document.createElement("div");
    div.classList.add("notification");
    div.classList.add("error");
    div.innerHTML = "<div class=\"content-container content-padding\"><p>" + error.message.replace("The user may have been deleted.","") + "</p></div>";
    document.getElementsByTagName("body")[0].appendChild(errorMessage(error));
    // Remove notification only after click
    div.addEventListener('click', () => div.style.opacity = '0');
    div.addEventListener('transitionend', () => div.remove());
}
// Creates message banner that fades out after 4 seconds
export function messageBanner(message, ms, isStatic) {
    // Create notification that meal has been saved
    let div = document.createElement("div");
    div.classList.add("notification");
    div.classList.add("saved");
    if (isStatic) {
        div.classList.add("static");
    }
    div.innerHTML = "<div class=\"content-container container-padding\"><p>" + message + "</p></div>";
    let body = document.getElementsByTagName("body")[0];
    body.insertBefore(div, body.querySelector(":first-child"));
    
    if (ms > 0) {
        setTimeout(() => {	// Fadeout and remove notification after a few seconds
            div.style.opacity = '0';
            div.addEventListener('transitionend', () => div.remove());
        }, 4000);
    }
    else {
        div.addEventListener('click', function handler() {
            div.remove();
            localStorage.setItem('CLOSED_NOTIFICATION', true);
            div.removeEventListener('click', handler);
        });
    }
}